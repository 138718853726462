import Transaction from '@/domain/Transaction';
import PlannedTransaction from '@/domain/PlannedTransaction';
import TransactionDigest from '@/domain/TransactionDigest';
import { TransactionStatus } from '@/domain/TransactionStatus';
import TransactionApiService from '@/services/api/TransactionApiService';
import { useNotification } from '@/composable/useNotifications';
import { downloadFile } from '@/functions/DownloadDocuments';
import { isPackList } from '@/functions/PrintTransaction';
import { formatDateTimeUI } from '@/functions/date';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import FilteredTransactionSearch from '@/dtos/filters/FilteredTransactionSearch';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default class TransactionService {
    private transactionApiService: TransactionApiService;

    private notification = useNotification();

    constructor() {
        this.transactionApiService = new TransactionApiService();
    }

    public async saveOrderedTransaction(transaction: Transaction): Promise<{ message: string; success: boolean }> {
        // eslint-disable-next-line no-param-reassign
        transaction.createdOn = new Date();
        // eslint-disable-next-line no-param-reassign
        transaction.status = TransactionStatus.ORDERED;

        const dto = transaction.toDTO();

        const response = await this.transactionApiService.createSupplierOrderTransaction(dto);

        return {
            success: response.success,
            message: response.message,
        };
    }

    public async getFilteredTransactions(filter: FilteredTransactionSearch): Promise<GetResultSetWithCountDTO<TransactionDigest>> {
        const dto = filter.toDTO;
        const { success, data } = await this.transactionApiService.getFilteredTransactions(dto);
        return success ? data : { resultSet: [], totalMatchedRecords: 0 };
    }

    public async getTransactionById(id: number): Promise<{ transaction: Transaction; success: boolean }> {
        const response = await this.transactionApiService.getTransactionById(id);

        if (!response.success) {
            this.notification.showError(getTranslation('core.validation.errorFetchingTransaction'));
        }

        return {
            transaction: response.data,
            success: response.success,
        };
    }

    public async updateTransaction(transaction: Transaction): Promise<{ message: string; success: boolean }> {
        const dto = transaction.toDTO();

        const response = await this.transactionApiService.updateTransaction(dto);

        if (!response.success) {
            this.notification.showError(response.message);
        }

        return {
            success: response.success,
            message: response.message,
        };
    }

    public async getPickableTransactionForLoading(fromLocationId: number, transactionId: number): Promise<Transaction | null> {
        const { success, data } = await this.transactionApiService.getPickableTransactionForLoading(fromLocationId, transactionId);
        return success ? data : null;
    }

    public async closeoutDisputedTransaction(transaction: Transaction): Promise<boolean> {
        const response = await this.transactionApiService.closeoutDisputedTransaction(transaction.toDTO());

        this.pushNotification(response, getTitleCaseTranslation('core.common.closingOutDisputedTransaction'));

        return response.success;
    }

    public async confirmTransaction(transaction: Transaction): Promise<boolean> {
        const response = await this.transactionApiService.confirmTransaction(transaction.toDTO());

        this.pushNotification(response, getTitleCaseTranslation('core.common.transactionConfirmed'));

        return response.success;
    }

    public async disputeTransaction(transaction: Transaction): Promise<boolean> {
        const response = await this.transactionApiService.disputeTransaction(transaction.toDisputeDTO());

        this.pushNotification(response, getTitleCaseTranslation('core.domain.transaction'));

        return response.success;
    }

    public async getTransactionTicketById(transactionId: number, status: TransactionStatus, actualDepartureDate: Date, plannedDepartureDate: Date) {
        const date = formatDateTimeUI(isPackList(status) ? actualDepartureDate : plannedDepartureDate);
        const downloadTicketResponse = await this.transactionApiService.getTransactionTicket(transactionId, date);
        if (downloadTicketResponse.success) {
            const fileName = isPackList(status) ? `Pack_List_${transactionId}.pdf` : `Pick_Ticket_${transactionId}.pdf`;
            await downloadFile(fileName, downloadTicketResponse.data);
        }
    }

    public async getBillOfLading(transactionId: number) {
        const downloadBillOfLadingResponse = await this.transactionApiService.getBillOfLading(transactionId, formatDateTimeUI(new Date()));
        if (downloadBillOfLadingResponse.success) {
            const fileName = `Bill_Of_Lading_${transactionId}.pdf`;
            await downloadFile(fileName, downloadBillOfLadingResponse.data);
        }
    }

    public async getBorderCrossingReport(transactionId: number) {
        const response = await this.transactionApiService.getBorderCrossingReport(transactionId);
        if (response.success) {
            const fileName = `ProForma_Border_Crossing_${transactionId}.pdf`;
            await downloadFile(fileName, response.data);
        }
    }

    public async getInboundReceipt(transactionId: number, arrivedAt: Date | undefined) {
        const response = await this.transactionApiService.getInboundReceipt(transactionId, arrivedAt ? formatDateTimeUI(arrivedAt) : undefined);
        if (response.success) {
            const fileName = `Inbound_Receipt_${transactionId}.pdf`;
            await downloadFile(fileName, response.data);
        }
    }

    private pushNotification<T>(response: DataAccessResponse<T>, successMsg: string) {
        if (response.success) {
            this.notification.showSuccess(successMsg);
        }
    }

    public async getInboundDeliveryConfirmedTransactions(toId: number): Promise<{ transactions: Array<Transaction>; success: boolean }> {
        const response = await this.transactionApiService.getInboundDeliveryConfirmedTransactions(toId);
        if (!response.success) {
            this.notification.showError();
        }

        return {
            transactions: response.data,
            success: response.success,
        };
    }

    public async getOutboundPlannedTransactions(fromLocationId: number): Promise<{ transactions: Array<PlannedTransaction>; success: boolean }> {
        const response = await this.transactionApiService.getOutboundPlannedTransactions(fromLocationId);

        return {
            transactions: response.data,
            success: response.success,
        };
    }

    public async cancelTransactionById(transactionId: number): Promise<boolean> {
        const response = await this.transactionApiService.cancelTransactionByIdAsync(transactionId);
        this.pushNotification(response, getTitleCaseTranslation('core.common.transactionRemoved'));
        return response.success;
    }

    public async deleteTransactionById(transactionId: number): Promise<boolean> {
        const response = await this.transactionApiService.deleteTransactionByIdAsync(transactionId);
        this.pushNotification(response, getTitleCaseTranslation('core.common.transactionDeleted'));
        return response.success;
    }
}
