export default abstract class CycleCountAdjustment {
    public inventoryAdjustment?: number;

    public newCount?: number;

    protected constructor(count?: number, systemCount?: number) {
        this.newCount = count;
        if (count !== undefined && systemCount !== undefined) this.setInitialAdjustment(count, systemCount);
    }

    protected setInitialAdjustment(count: number, systemCount: number, underLoadPieces: number = 0) {
        this.newCount = ((count) + (underLoadPieces));
        this.inventoryAdjustment = ((count) + (underLoadPieces)) - (systemCount);
    }
}
