
import { defineComponent, PropType, reactive } from 'vue';
import QuantityPicker from '@/components/QuantityPicker.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { useNotification } from '@/composable/useNotifications';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import NumberInput from '@/components/inputs/NumberInput.vue';

type State = {
    count: number | undefined;
    busy: boolean;
};

export default defineComponent({
    name: 'cycle-count-qty-picker',
    components: {
        BSpinner,
        BButton,
        QuantityPicker,
        NumberInput,
    },
    props: {
        busy: Boolean,
        onSubmitPromise: Function as PropType<(count: number) => Promise<boolean>>,
        placeholder: {
            type: String,
            default: () => getTitleCaseTranslation('core.domain.count'),
        },
        disabled: Boolean,
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'onSubmit', 'reset'],
    setup(props, context) {
        const { capitalizeFirstLetter } = useStringFormatter();
        const { confirm } = useDialogBox();

        const state = reactive<State>({
            count: 0,
            busy: false,
        });

        async function onReset() {
            if (await confirm({
                message: getTranslation('core.common.areYouSureYouWantToResetTheCount'),
                title: getTranslation('core.common.areYouSure'),
            })) {
                context.emit('reset');
            }
        }

        async function onSubmit() {
            if (state.count === undefined) {
                useNotification().showError(capitalizeFirstLetter(getTranslation('core.common.mustEnterCount')));
                return;
            }
            if (props.onSubmitPromise) {
                state.busy = true;
                await props.onSubmitPromise(state.count);
                state.busy = false;
            } else {
                context.emit('onSubmit', state.count);
            }

            state.count = 0;
        }

        return {
            state,
            onSubmit,
            getTitleCaseTranslation,
            onReset,
        };
    },
});
