import CycleCountItemTableRow from '@/domain/cyclecount/CycleCountItemTableRow';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';

export default class CycleCountItemTable {
    public cycleCountItemTableRows: Array<CycleCountItemTableRow>;

    constructor(cycleCountItems: Array<CycleCountItem>) {
        let key = 1;

        this.cycleCountItemTableRows = cycleCountItems.reduce((cycleCountItemTableRows, cycleCountItem) => {
            const tableRow = cycleCountItemTableRows.find((x) => (x.item && x.item?.id === cycleCountItem.item?.id) || (x.plannedPart && x.plannedPart?.id === cycleCountItem.plannedPart?.id));

            if (tableRow) {
                tableRow.cycleCountItems.push(cycleCountItem);
            } else {
                cycleCountItemTableRows.push(new CycleCountItemTableRow({
                    id: key++,
                    item: cycleCountItem.item,
                    plannedPart: cycleCountItem.plannedPart,
                    cycleCountItems: [cycleCountItem],
                }));
            }

            return cycleCountItemTableRows;
        }, new Array<CycleCountItemTableRow>());
    }
}
