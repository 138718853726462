
import { defineComponent, PropType, reactive } from 'vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import InventoryCategory from '@/domain/InventoryCategory';

type State = {
    count: number;
    busy: boolean;
};

export default defineComponent({
    name: 'cycle-count-report-mobile-count-item',
    components: {
        BCol,
        BRow,
    },
    props: {
        itemName: {
            type: String,
            required: true,
        },
        itemCount: {
            type: Number,
            default: () => undefined,
            required: false,
        },
        dateCounted: {
            type: String,
            default: () => undefined,
            required: false,
        },
        item: {
            type: Object as PropType<CycleCountItem>,
            required: true,
        },
        inventoryCategory: {
            type: InventoryCategory as PropType<InventoryCategory>,
            required: true,
        },
        onSubmitPromise: {
            // eslint-disable-next-line no-unused-vars
            type: Function as PropType<(count: number) => Promise<boolean>>,
            required: true,
        },
    },
    emits: ['submitCount', 'reset'],
    setup(props, context) {
        const state = reactive<State>({
            count: 0,
            busy: false,
        });

        const { confirm } = useDialogBox();

        async function onReset() {
            if (
                await confirm({
                    message: getTranslation('core.common.areYouSureYouWantToResetTheCount'),
                    title: getTranslation('core.common.areYouSure'),
                })
            ) {
                context.emit('reset');
            }
        }

        async function onSubmit() {
            if (props.onSubmitPromise) {
                state.busy = true;
                await props.onSubmitPromise(state.count);
                state.busy = false;
            } else {
                context.emit('submitCount', props.item, state.count);
            }

            state.count = 0;
        }

        return {
            state,
            getTitleCaseTranslation,
            onReset,
            onSubmit,
        };
    },
});
