import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4821505e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cycle-count-qty-picker-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_quantity_picker = _resolveComponent("quantity-picker")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_quantity_picker, {
          key: 0,
          modelValue: _ctx.state.count,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.count) = $event)),
          placeholder: _ctx.placeholder,
          disabled: _ctx.state.busy || _ctx.busy,
          required: true
        }, null, 8, ["modelValue", "placeholder", "disabled"]))
      : (_openBlock(), _createBlock(_component_number_input, {
          key: 1,
          modelValue: _ctx.state.count,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.count) = $event)),
          placeholder: _ctx.placeholder,
          disabled: _ctx.state.busy || _ctx.busy,
          required: true
        }, null, 8, ["modelValue", "placeholder", "disabled"])),
    _createVNode(_component_b_button, {
      type: "submit",
      class: "btn-submit-count",
      disabled: _ctx.busy || _ctx.state.busy || _ctx.disabled,
      variant: "primary",
      onClick: _ctx.onSubmit
    }, {
      default: _withCtx(() => [
        (_ctx.busy || _ctx.state.busy)
          ? (_openBlock(), _createBlock(_component_b_spinner, {
              key: 0,
              small: ""
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_b_button, {
      class: "btn-reset-count",
      disabled: _ctx.busy || _ctx.state.busy || _ctx.disabled,
      variant: "secondary",
      onClick: _ctx.onReset
    }, {
      default: _withCtx(() => [
        (_ctx.busy || _ctx.state.busy)
          ? (_openBlock(), _createBlock(_component_b_spinner, {
              key: 0,
              small: ""
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.reset')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}