import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4650aa44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cycle-count-mobile-item-container" }
const _hoisted_2 = { class: "vertical-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createElementVNode("label", null, _toDisplayString(_ctx.itemName), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, null, {
          default: _withCtx(() => [
            _createElementVNode("label", _hoisted_2, _toDisplayString(`${_ctx.getTitleCaseTranslation('core.domain.category')}: ${_ctx.inventoryCategory.description}`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_number_input, {
              modelValue: _ctx.state.count,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.count) = $event)),
              class: "mobile-number-input",
              placeholder: _ctx.getTitleCaseTranslation('core.domain.count'),
              disabled: _ctx.state.busy,
              required: true
            }, null, 8, ["modelValue", "placeholder", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, { class: "mobile-button-col" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              class: "btn-cycle-count",
              disabled: _ctx.state.busy,
              variant: "secondary",
              onClick: _ctx.onReset
            }, {
              default: _withCtx(() => [
                (_ctx.state.busy)
                  ? (_openBlock(), _createBlock(_component_b_spinner, {
                      key: 0,
                      small: ""
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.clear')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, { class: "mobile-button-col" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              type: "submit",
              class: "btn-cycle-count",
              disabled: _ctx.state.busy,
              variant: "primary",
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => [
                (_ctx.state.busy)
                  ? (_openBlock(), _createBlock(_component_b_spinner, {
                      key: 0,
                      small: ""
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_b_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_b_col, null, {
          default: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.count') + ': ' + (_ctx.itemCount ?? '')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_col, null, {
          default: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.dateCounted') + ': ' + (_ctx.dateCounted ?? '')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}