import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-198c9f60"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cycle-report-content"
}
const _hoisted_2 = { class: "cycle-count-table-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "align-left"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 2,
  class: "cycle-report-complete"
}
const _hoisted_7 = { class: "complete-title" }
const _hoisted_8 = {
  key: 1,
  class: "empty-report-screen"
}
const _hoisted_9 = {
  key: 0,
  style: {"margin":"auto"}
}
const _hoisted_10 = { class: "cycle-report-footer" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_cycle_count_report_approve = _resolveComponent("cycle-count-report-approve")!
  const _component_cycle_count_report_count = _resolveComponent("cycle-count-report-count")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    full: "",
    class: "cycle-count-report-screen",
    title: _ctx.title,
    "go-back": _ctx.goBack
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showItemSearch,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
        centered: "",
        "hide-footer": "",
        title: _ctx.getTitleCaseTranslation('core.button.addItem')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_dropdown_autocomplete_single_select, {
                loading: _ctx.state.loading,
                "model-value": _ctx.state.newItemInventoryCategory?.description,
                data: _ctx.inventoryCategoryStore.canMoveInventoryCategories,
                "search-by": "description",
                "empty-text": _ctx.getTitleCaseTranslation('core.validation.noDocks'),
                "display-name": "description",
                label: _ctx.getTitleCaseTranslation('core.domain.inventoryCategory'),
                onOnSelect: _ctx.updateNewItemInventoryCategory
              }, null, 8, ["loading", "model-value", "data", "empty-text", "label", "onOnSelect"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.item')), 1),
                  _createVNode(_component_item_picker, {
                    "item-type": _ctx.ItemType.RECEIVING,
                    "button-title": _ctx.getTitleCaseTranslation('core.button.submitCount'),
                    "allow-quantity-change": "",
                    "error-msg": _ctx.state.itemPickerErrorMsg,
                    busy: _ctx.state.busy,
                    "is-mobile": _ctx.isMobile,
                    "disabled-qty-picker": !_ctx.state.newItemInventoryCategory,
                    onOnSelect: _ctx.onItemSelect
                  }, null, 8, ["item-type", "button-title", "error-msg", "busy", "is-mobile", "disabled-qty-picker", "onOnSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      (!_ctx.state.loading && _ctx.state.report)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.isMobile ? 'container-header-mobile' : 'container-header')
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.state.report?.location.name), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.report?.location.locationAddresses, (contact) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: contact.streetAddress,
                      class: "align-left"
                    }, [
                      (!_ctx.isMobile)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                            _createElementVNode("strong", null, _toDisplayString(contact.typeDescription), 1),
                            _createTextVNode(" " + _toDisplayString(contact.contactName || '-') + " | " + _toDisplayString(contact.phoneNumber || '-') + " | " + _toDisplayString(contact.emailAddress || ''), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128)),
                  (_ctx.state.report?.location.locationAddresses?.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.contactInformationNotFound')), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, [
                    _createElementVNode("span", {
                      class: _normalizeClass(['report-due-date', { error: _ctx.state.report?.isPastDue }])
                    }, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.due')) + " " + _toDisplayString(_ctx.state.report?.dueDate.toDateString()), 3)
                  ]),
                  (_ctx.state.report?.status === _ctx.CycleCountReportStatus.Closed)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.closed')) + " " + _toDisplayString(_ctx.state.report?.closedDate?.toDateString()), 1))
                    : _createCommentVNode("", true)
                ])
              ], 2),
              (_ctx.screen === 'approve')
                ? (_openBlock(), _createBlock(_component_cycle_count_report_approve, {
                    key: 0,
                    report: _ctx.state.report as CycleCountReport,
                    "disable-adjustments": !_ctx.canEditCount || _ctx.state.report?.status === _ctx.CycleCountReportStatus.Closed
                  }, null, 8, ["report", "disable-adjustments"]))
                : _createCommentVNode("", true),
              (_ctx.screen === 'count')
                ? (_openBlock(), _createBlock(_component_cycle_count_report_count, {
                    key: 1,
                    modelValue: _ctx.state.report as CycleCountReport,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.report as CycleCountReport) = $event)),
                    "is-mobile": _ctx.isMobile
                  }, null, 8, ["modelValue", "is-mobile"]))
                : _createCommentVNode("", true),
              (_ctx.screen === 'end')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.cycleCount')) + " " + _toDisplayString(_ctx.state.report?.status === _ctx.CycleCountReportStatus.Closed ? _ctx.getTitleCaseTranslation('core.common.closed') : _ctx.getTitleCaseTranslation('core.common.awaitingApproval')), 1),
                    (_ctx.dashboard)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 0,
                          onClick: _ctx.goToCycleCountDashboard
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.cycleCountDashboard')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.state.report)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            (_ctx.state.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getTranslation('core.common.loading')) + "...", 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.state.loading)
              ? (_openBlock(), _createBlock(_component_empty_state, {
                  key: 1,
                  title: _ctx.nullReportMessage,
                  icon: "boxes",
                  button: 
                    _ctx.dashboard
                        ? {
                            text: _ctx.getTitleCaseTranslation('core.common.dashboard'),
                            onClick: _ctx.goToCycleCountDashboard,
                        }
                        : undefined
                
                }, null, 8, ["title", "button"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (!_ctx.state.loading && _ctx.state.report)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(_ctx.state.report?.percentComplete) + "% " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.complete')), 1),
                (_ctx.state.report?.status === _ctx.CycleCountReportStatus.AwaitingApproval)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, " - " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.awaitingApproval')), 1))
                  : (_ctx.state.report?.status === _ctx.CycleCountReportStatus.Closed)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, " - " + _toDisplayString(_ctx.getTitleCaseTranslation('core.common.closed')), 1))
                    : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, [
                (_ctx.state.report?.status !== _ctx.CycleCountReportStatus.Open && _ctx.canApproveCount)
                  ? (_openBlock(), _createBlock(_component_b_dropdown, {
                      key: 0,
                      "no-caret": "",
                      "close-on-click": "",
                      "drop-up": "",
                      title: _ctx.getTitleCaseTranslation('core.common.actions'),
                      "is-button": "",
                      left: ""
                    }, {
                      default: _withCtx(() => [
                        (_ctx.state.report?.status !== _ctx.CycleCountReportStatus.Closed)
                          ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                              key: 0,
                              onClick: _ctx.closeReport
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.closeAsIs')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.state.report?.status !== _ctx.CycleCountReportStatus.Closed)
                          ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                              key: 1,
                              onClick: _ctx.closeReportAndAdjustInventory
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.closeWithAdjustments')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.state.report?.status === _ctx.CycleCountReportStatus.Closed)
                          ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                              key: 2,
                              onClick: _ctx.reopenReport
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getTranslation('core.button.reopen')), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["title"]))
                  : (_ctx.state.report?.status === _ctx.CycleCountReportStatus.Open)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_b_button, {
                          class: "add-item-offset",
                          disabled: _ctx.state.busy,
                          onClick: _ctx.searchContainer
                        }, {
                          default: _withCtx(() => [
                            (_ctx.state.busy)
                              ? (_openBlock(), _createBlock(_component_b_spinner, {
                                  key: 0,
                                  small: ""
                                }))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItem')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"]),
                        _createVNode(_component_b_button, {
                          disabled: _ctx.state.busy || !_ctx.state.report?.isCountComplete,
                          onClick: _ctx.finalizeCounts
                        }, {
                          default: _withCtx(() => [
                            (_ctx.state.busy)
                              ? (_openBlock(), _createBlock(_component_b_spinner, {
                                  key: 0,
                                  small: ""
                                }))
                              : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_ctx.getTitleCaseTranslation('core.button.finalizeCounts')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ])
        }
      : undefined
  ]), 1032, ["title", "go-back"]))
}