import PlannedPart from '@/domain/PlannedPart';
import { normalizeDate } from '@/functions/date';
import { i18n } from '@/translation/i18n';

export default class PlannedPartDTO {
    public id: number = 0;

    public fromLocationId: number = 0;
    public fromLocationNameAndNumber: string = '';

    public toLocationId: number = 0;
    public toLocationNameAndNumber: string = '';

    public itemId: number = 0;
    public itemNameAndNumber: string = '';
    public quantityPerItem: number = 0;

    public partId: number = 0;
    public partNumber: string = '';

    public effectiveFrom?: Date;
    public effectiveTo?: Date;

    public enabled: boolean = false;
    public enabledView: string = '';

    public isPalletized: boolean = false;
    public isPalletizedView: string = '';
    public itemsPerPallet?: number;
    public palletItemId?: number;
    public palletItemNameAndNumber: string = '';

    public includeTopCap?: boolean;
    public includeTopCapView?: string = '';
    public topCapItemId?: number;
    public topCapItemNameAndNumber: string = '';

    constructor(part?: PlannedPart | PlannedPartDTO) {
        if (part && part instanceof PlannedPart) {
            this.id = part?.id || 0;
            this.fromLocationId = part?.fromLocation?.id || 0;
            this.fromLocationNameAndNumber = part?.fromLocation?.id ? [part.fromLocation.name, part.fromLocation.locationNumber].join(' | ') : '';
            this.toLocationId = part?.toLocation?.id || 0;
            this.toLocationNameAndNumber = part?.toLocation?.id ? [part.toLocation.name, part.toLocation.locationNumber].join(' | ') : '';
            this.itemId = part?.item?.id || 0;
            this.itemNameAndNumber = part?.item?.id ? [part.item.name, part.item.customerItemNumber].join(' | ') : '';
            this.partId = part?.part?.id || 0;
            this.partNumber = part?.part?.number || '';
            this.quantityPerItem = part?.quantityPerItem || 0;
            this.effectiveFrom = normalizeDate(part?.effectiveFrom);
            this.effectiveTo = normalizeDate(part?.effectiveTo);
            this.enabled = part?.enabled || false;
            this.enabledView = part?.enabled ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.isPalletized = part?.isPalletized || false;
            this.isPalletizedView = part?.isPalletized ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.palletItemId = part?.palletItem?.id || undefined;
            this.palletItemNameAndNumber = part?.palletItem?.id ? [part.palletItem.name, part.palletItem.customerItemNumber].join(' | ') : '';
            this.itemsPerPallet = part?.itemsPerPallet || undefined;
            this.includeTopCap = part?.includeTopCap;
            this.includeTopCapView = part?.includeTopCap ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.topCapItemId = part?.topCapItem?.id || undefined;
            this.topCapItemNameAndNumber = part?.topCapItem?.id ? [part.topCapItem.name, part.topCapItem.customerItemNumber].join(' | ') : '';
        } else {
            this.id = part?.id || 0;
            this.fromLocationId = part?.fromLocationId || 0;
            this.fromLocationNameAndNumber = part?.fromLocationNameAndNumber || '';
            this.toLocationId = part?.toLocationId || 0;
            this.toLocationNameAndNumber = part?.toLocationNameAndNumber || '';
            this.itemId = part?.itemId || 0;
            this.itemNameAndNumber = part?.itemNameAndNumber || '';
            this.partId = part?.partId || 0;
            this.partNumber = part?.partNumber || '';
            this.quantityPerItem = part?.quantityPerItem || 0;
            this.effectiveFrom = normalizeDate(part?.effectiveFrom);
            this.effectiveTo = normalizeDate(part?.effectiveTo);
            this.enabled = part?.enabled || false;
            this.enabledView = part?.enabled ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.isPalletized = part?.isPalletized || false;
            this.isPalletizedView = part?.isPalletized ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.palletItemId = part?.palletItemId || undefined;
            this.palletItemNameAndNumber = part?.palletItemNameAndNumber || '';
            this.itemsPerPallet = part?.itemsPerPallet || undefined;
            this.includeTopCap = part?.includeTopCap;
            this.includeTopCapView = part?.includeTopCap ? i18n.global.t('core.common.yes') : i18n.global.t('core.common.no');
            this.topCapItemId = part?.topCapItemId || undefined;
            this.topCapItemNameAndNumber = part?.topCapItemNameAndNumber || '';
        }
    }
}
