import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_table = _resolveComponent("b-table")!

  return (_openBlock(), _createBlock(_component_b_table, {
    items: _ctx.tableData,
    fields: _ctx.fields,
    class: "cycle-count-table",
    "row-height": "64px",
    "accentuate-rows": true
  }, {
    "cell(newCounts)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id,
          class: _normalizeClass(["item-detail-cell item-detail-cell-count", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
        }, {
          default: _withCtx(() => [
            (!_ctx.disableAdjustments)
              ? (_openBlock(), _createBlock(_component_b_form_input, {
                  key: 0,
                  modelValue: row.item.newCounts[index],
                  "onUpdate:modelValue": ($event: any) => ((row.item.newCounts[index]) = $event),
                  type: "number",
                  style: {"width":"100px"},
                  "hide-stepper": "",
                  onChange: (value) => _ctx.updateAdjustmentQuantity(value, itemInventoryCategory)
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
              : _createCommentVNode("", true),
            (_ctx.disableAdjustments)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : '')
                }, _toDisplayString(row.item.newCounts[index]), 3))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    "cell(dateCounted)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", {
              class: _normalizeClass(["item-detail-cell item-detail-cell-label", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
            }, _toDisplayString(itemInventoryCategory.dateCounted ? itemInventoryCategory.dateCounted.toDateString() : ''), 3)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    "cell(count)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id,
          class: _normalizeClass(["item-detail-cell item-detail-cell-count", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(itemInventoryCategory.count), 1)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    "cell(category)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", {
              class: _normalizeClass(["item-detail-cell item-detail-cell-label", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
            }, _toDisplayString(itemInventoryCategory.inventoryCategory.description), 3)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    "cell(underLoadPieces)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id,
          class: _normalizeClass(["item-detail-cell item-detail-cell-count", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(itemInventoryCategory.underLoadPieces), 1)
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    "cell(systemCount)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", {
              class: _normalizeClass(["item-detail-cell item-detail-cell-label", index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : ''])
            }, _toDisplayString(itemInventoryCategory.systemCount ?? '-'), 3)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    "cell(inventoryAdjustment)": _withCtx((row) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.item.cycleCountItemRow.cycleCountItems, (itemInventoryCategory, index) => {
        return (_openBlock(), _createBlock(_component_b_row, {
          key: itemInventoryCategory.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", {
              class: _normalizeClass(["item-detail-cell item-detail-cell-label", [index < row.item.cycleCountItemRow.cycleCountItems.length - 1 ? 'item-detail-cell-not-last' : '', { bold: itemInventoryCategory.isOutsideOfThreshold}]])
            }, _toDisplayString(itemInventoryCategory.inventoryAdjustment), 3)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["items", "fields"]))
}