export default class ProductionPart {
    constructor(init?: Partial<ProductionPart>) {
        this.id = init?.id || 0;
        this.number = init?.number || '';
        this.name = init?.name || '';
        this.length = init?.length || 0;
        this.width = init?.width || 0;
        this.height = init?.height || 0;
        this.weight = init?.weight || 0;
        this.description = init?.description;
    }

    public id: number = 0;

    public name!: string;

    public number!: string;

    public length!: number;

    public width!: number;

    public height!: number;

    public weight!: number;

    public description?: string;

    get descriptiveDisplayNumber(): string {
        return `${this.number} | ${this.description}`;
    }

    get isNew(): boolean {
        return this.id === 0;
    }
}
