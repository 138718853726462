import Item from '@/domain/Item';
import ProductionPart from '@/domain/ProductionPart';
import Location from '@/domain/Location';
import PlannedPartDTO from '@/dtos/PlannedPartDTO';

export default class PlannedPart {
    constructor(part?: Partial<PlannedPart> | PlannedPartDTO) {
        if (part && part instanceof PlannedPartDTO) {
            this.id = part?.id || 0;
            this.fromLocation = new Location();
            this.toLocation = new Location();
            this.item = new Item();
            this.part = new ProductionPart();
            this.quantityPerItem = part?.quantityPerItem || 0;
            this.effectiveFrom = part?.effectiveFrom;
            this.effectiveTo = part?.effectiveTo;
            this.enabled = part?.enabled || false;
            this.includeTopCap = part?.includeTopCap || false;
            this.isPalletized = part?.isPalletized || false;
            this.itemsPerPallet = part?.itemsPerPallet;
            this.palletItem = undefined;
            this.topCapItem = undefined;
        } else {
            this.id = part?.id || 0;
            this.fromLocation = new Location(part?.fromLocation) || new Location();
            this.toLocation = new Location(part?.toLocation) || new Location();
            this.item = new Item(part?.item) || new Item();
            this.part = new ProductionPart(part?.part) || new ProductionPart();
            this.quantityPerItem = part?.quantityPerItem || 0;
            this.effectiveFrom = part?.effectiveFrom;
            this.effectiveTo = part?.effectiveTo;
            this.enabled = this.id === 0 ? true : part?.enabled || false;
            this.includeTopCap = part?.includeTopCap || false;
            this.isPalletized = part?.isPalletized || false;
            this.itemsPerPallet = part?.itemsPerPallet;
            this.palletItem = part?.palletItem || undefined;
            this.topCapItem = part?.topCapItem || undefined;
        }
    }

    public id: number = 0;
    public fromLocation: Location = new Location();
    public toLocation: Location = new Location();
    public item: Item = new Item();
    public part: ProductionPart;
    public quantityPerItem!: number;
    public effectiveFrom?: Date;
    public effectiveTo?: Date;
    public enabled!: boolean;
    public includeTopCap!: boolean;
    public isPalletized!: boolean;
    public itemsPerPallet?: number;
    public palletItem?: Item;
    public topCapItem?: Item;

    public setItem(item: Item) {
        this.item = item;
    }

    public setPalletItem(item?: Item) {
        this.palletItem = item;
    }

    public setIsPalletized(value: boolean) {
        this.isPalletized = value;
    }

    public setTopCapItem(item?: Item) {
        this.topCapItem = item;
    }

    public setIncludeTopCap(value: boolean) {
        this.includeTopCap = value;
    }

    public setFromLocation(location: Location) {
        this.fromLocation = location;
    }

    public setPart(part: ProductionPart) {
        this.part = part;
    }

    public setToLocation(location: Location) {
        this.toLocation = location;
    }

    public setItemsPerPallet(value?: number) {
        this.itemsPerPallet = value;
    }

    public clear() {
        this.includeTopCap = false;
        this.isPalletized = false;
        this.item = new Item();
        this.itemsPerPallet = undefined;
        this.palletItem = undefined;
        this.quantityPerItem = 0;
        this.topCapItem = undefined;
        this.toLocation = new Location();
    }

    public clearPalletAndTopCap() {
        this.setItemsPerPallet(undefined);
        this.setIncludeTopCap(false);
        this.setPalletItem(undefined);
        this.setTopCapItem(undefined);
    }

    public toDTO(): PlannedPartDTO {
        return new PlannedPartDTO(this);
    }
}
