import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import { normalizeDate } from '@/functions/date';

export default class PlannedTransaction {
    public id: number = 0;

    public fromLocationId?: number;
    public fromLocationName?: string;
    public toLocationId: number = 0;
    public toLocationName: string = '';
    public status!: TransactionStatus;
    public createdOn!: Date;
    public shipDate!: Date;
    public dateRequired!: Date;

    constructor(init?: Partial<PlannedTransaction>) {
        this.id = init?.id || 0;
        this.fromLocationId = init?.fromLocationId || undefined;
        this.fromLocationName = init?.fromLocationName || undefined;
        this.toLocationId = init?.toLocationId || 0;
        this.toLocationName = init?.toLocationName || '';
        this.status = init?.status || TransactionStatus.PLANNED;

        if (init?.createdOn) {
            this.createdOn = normalizeDate(init.createdOn);
        }

        if (init?.shipDate) {
            this.shipDate = normalizeDate(init.shipDate);
        }

        if (init?.dateRequired) {
            this.dateRequired = normalizeDate(init.dateRequired);
        }
    }

    get transactionStatusDescription(): string {
        return Formatter.GetFriendlyValue(this.status);
    }
}
