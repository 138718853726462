import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import TransactionDTO from '@/modules/floortrak/domain/jigsaw/dto/TransactionDTO';
import Transaction from '@/domain/Transaction';
import TransactionDigest from '@/domain/TransactionDigest';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import { FilteredTransactionSearchDTO } from '@/dtos/filters/FilteredTransactionSearch';
import DisputeTransactionDTO from '@/dtos/DisputeTransactionDTO';
import PlannedTransaction from '@/domain/PlannedTransaction';

export default class TransactionApiService {
    public async createSupplierOrderTransaction(transaction: TransactionDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            data: transaction,
            url: `${API_SERVICE_URL}/transaction/supplier-order`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getTransactionById(id: number): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/${id}`,
        });
        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async getFilteredTransactions(dto: FilteredTransactionSearchDTO): Promise<DataAccessResponse<GetResultSetWithCountDTO<TransactionDigest>>> {
        const response: AxiosResponse<DataAccessResponse<GetResultSetWithCountDTO<TransactionDigest>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/transaction/advanced-search`,
            data: dto,
        });
        response.data.data.resultSet = response.data.data.resultSet.map((t) => new TransactionDigest(t));

        return new DataAccessResponseClass<GetResultSetWithCountDTO<TransactionDigest>>(response).response;
    }

    public async getPickableTransactionForLoading(fromId: number, transactionId: number): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/existing/outbound/load-pickable`,
            params: { fromId, transactionId },
        });

        return new DataAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async getTransactionTicket(transactionId: number, shipDateLabel: string): Promise<DataAccessResponse<Blob>> {
        const response: AxiosResponse<Blob> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/${transactionId}/ticket`,
            responseType: 'blob',
            params: { shipDateLabel },
        });

        return {
            success: Boolean(response.data),
            data: response.data,
            message: response.data ? '' : 'Unable to download pdf',
        } as DataAccessResponse<Blob>;
    }

    public async getBillOfLading(transactionId: number, shipDateLabel: string) {
        const response: AxiosResponse<Blob> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/${transactionId}/bill-of-lading`,
            responseType: 'blob',
            params: { shipDateLabel },
        });

        return {
            success: Boolean(response.data),
            data: response.data,
            message: response.data ? '' : 'Unable to download pdf',
        } as DataAccessResponse<Blob>;
    }

    public async getBorderCrossingReport(transactionId: number) {
        const response: AxiosResponse<Blob> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/${transactionId}/proforma-border-crossing`,
            responseType: 'blob',
        });

        return {
            success: Boolean(response.data),
            data: response.data,
            message: response.data ? '' : 'Unable to download pdf',
        } as DataAccessResponse<Blob>;
    }

    public async getInboundReceipt(transactionId: number, arrivedAtLabel: string | undefined): Promise<DataAccessResponse<Blob>> {
        const response: AxiosResponse = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/${transactionId}/inbound-receipt`,
            responseType: 'blob',
            params: { arrivedAtLabel },
        });

        return {
            success: Boolean(response.data),
            data: response.data,
            message: response.data ? '' : 'Unable to download pdf',
        } as DataAccessResponse<Blob>;
    }

    public async updateTransaction(transaction: TransactionDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction`,
            data: transaction,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async closeoutDisputedTransaction(transaction: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction/closeout-disputed`,
            data: transaction,
        });

        return new DataAccessResponseClass<Transaction>(response).response;
    }

    public async confirmTransaction(transaction: TransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction/confirm`,
            data: transaction,
        });

        return new DataAccessResponseClass<Transaction>(response).response;
    }

    public async disputeTransaction(transaction: DisputeTransactionDTO): Promise<DataAccessResponse<Transaction>> {
        const response: AxiosResponse<DataAccessResponse<Transaction>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction/dispute`,
            data: transaction,
        });

        return new DataAccessResponseClass<Transaction>(response).response;
    }

    public async getInboundDeliveryConfirmedTransactions(toId: number): Promise<DataAccessResponse<Array<Transaction>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Transaction>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/inbound-delivery-confirmed`,
            params: {
                toId,
            },
        });

        return new DataListAccessResponseClass<Transaction>(response, Transaction).response;
    }

    public async getOutboundPlannedTransactions(fromLocationId: number): Promise<DataAccessResponse<Array<PlannedTransaction>>> {
        const response: AxiosResponse<DataAccessResponse<Array<PlannedTransaction>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/transaction/outbound-planned/${fromLocationId}`,
        });

        return new DataListAccessResponseClass<PlannedTransaction>(response, PlannedTransaction).response;
    }

    public async cancelTransactionByIdAsync(transactionId: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction/cancel-transaction-by-id`,
            params: {
                transactionId,
            },
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async deleteTransactionByIdAsync(transactionId: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/transaction/delete-transaction-by-id`,
            params: {
                transactionId,
            },
        });
        return new DataAccessResponseClass<number>(response).response;
    }
}
