import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import SubmitCycleCountItemDTO from '@/dtos/cyclecount/SubmitCycleCountItemDTO';
import SubmitCycleCountItemResponseDTO from '@/dtos/cyclecount/SubmitCycleCountItemResponseDTO';
import { CycleCountSearchFilterDTO } from '@/dtos/filters/CycleCountSearchFilter';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import CloseCycleCountAndAdjustInventoryDTO from '@/dtos/cyclecount/CloseCycleCountAndAdjustInventoryDTO';
import Transaction from '@/domain/Transaction';
import ResetCycleCountItemDTO from '@/dtos/cyclecount/ResetCycleCountItemDTO';
import CycleCountConfiguration from '@/domain/cyclecount/CycleCountConfiguration';

export default class CycleCountReportApiService {
    public async getCycleCountReportsByFilter(dto: CycleCountSearchFilterDTO): Promise<DataAccessResponse<GetResultSetWithCountDTO<CycleCountReport>>> {
        const response: AxiosResponse<DataAccessResponse<GetResultSetWithCountDTO<CycleCountReport>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/cyclecountreport/list/filter`,
            data: dto,
        });
        response.data.data.resultSet = response.data.data.resultSet.map((c) => new CycleCountReport(c));

        return new DataAccessResponseClass<GetResultSetWithCountDTO<CycleCountReport>>(response).response;
    }

    public async getCycleCountReportById(reportId: number): Promise<DataAccessResponse<CycleCountReport | null>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountReport>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountreport/${reportId}`,
        });

        return new DataAccessResponseClass<CycleCountReport>(response, CycleCountReport).response;
    }

    public async getOpenCycleCountReportByLocation(reportId: number): Promise<DataAccessResponse<CycleCountReport>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountReport>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountreport/location/${reportId}`,
        });

        return new DataAccessResponseClass<CycleCountReport>(response, CycleCountReport).response;
    }

    public async getUncountedCycleCountReportCountByLocation(locationId: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountreport/location-count/uncounted/${locationId}`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async submitCycleCountItem(dto: SubmitCycleCountItemDTO): Promise<DataAccessResponse<SubmitCycleCountItemResponseDTO>> {
        const response: AxiosResponse<DataAccessResponse<SubmitCycleCountItemResponseDTO>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/cyclecountreport/submit-count`,
            data: dto,
        });

        if (response?.data.success) {
            response.data.data.inboundDeliveryConfirmedTransactions = response.data.data.inboundDeliveryConfirmedTransactions.map((t) => new Transaction(t));
        }

        return new DataAccessResponseClass<SubmitCycleCountItemResponseDTO>(response).response;
    }

    public async closeReport(reportId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/cyclecountreport/close/${reportId}`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async closeReportAndAdjustInventory(dto: CloseCycleCountAndAdjustInventoryDTO): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/cyclecountreport/close-and-adjust`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async finalizeCounts(reportId: number): Promise<DataAccessResponse<CycleCountReport>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountReport>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountreport/finalize/${reportId}`,
        });

        return new DataAccessResponseClass<CycleCountReport>(response, CycleCountReport).response;
    }

    public async resetCycleCountItem(dto: ResetCycleCountItemDTO): Promise<DataAccessResponse<CycleCountReport>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountReport>> = await axios({
            data: dto,
            method: 'POST',
            url: `${API_SERVICE_URL}/cyclecountreport/reset-count`,
        });

        return new DataAccessResponseClass<CycleCountReport>(response, CycleCountReport).response;
    }

    public async reopenReportById(reportId: number): Promise<DataAccessResponse<CycleCountReport | null>> {
        const response: AxiosResponse<DataAccessResponse<CycleCountReport>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/cyclecountreport/reopen/reportId/${reportId}`,
        });

        return new DataAccessResponseClass<CycleCountReport>(response, CycleCountReport).response;
    }

    public async createOneTimeCycleCountReport(dto: CycleCountConfiguration): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/cyclecountreport/one-time`,
            data: dto,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
