import PlannedPart from '@/domain/PlannedPart';
import Item from '@/domain/Item';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';

export default class CycleCountItemTableRow {
    public id: number;
    public item?: Item;
    public plannedPart?: PlannedPart;
    public cycleCountItems: Array<CycleCountItem> = [];

    constructor(data: { id: number, item?: Item, plannedPart?: PlannedPart, cycleCountItems: Array<CycleCountItem> }) {
        this.id = data.id;
        this.item = data.item ? new Item(data.item) : undefined;
        this.plannedPart = data.plannedPart ? new PlannedPart(data.plannedPart) : undefined;
        this.cycleCountItems = data.cycleCountItems;
    }
}
