import { TransactionStatus } from '@/domain/TransactionStatus';

export function isPackList(status: TransactionStatus): boolean {
    return status >= TransactionStatus.HELD;
}

export function isPickTicket(status: TransactionStatus): boolean {
    return status < TransactionStatus.HELD;
}

export function isPrintDisabled(status: TransactionStatus) {
    return TransactionStatus.ORDERED === status;
}

export function isBillOfLadingDisabled(status: TransactionStatus): boolean {
    return status < TransactionStatus.PICKED;
}

export function isConfirmed(status: TransactionStatus): boolean {
    return status === TransactionStatus.CONFIRMED;
}
