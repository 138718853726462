
import {
    computed, defineComponent, PropType, reactive, onMounted
} from 'vue';
import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import CycleCountItemTable from '@/domain/cyclecount/CycleCountItemTable';
import BRow from '@/components/bootstrap-library/BRow.vue';
import CycleCountItemTableRow from '@/domain/cyclecount/CycleCountItemTableRow';

type TableData = {
    itemId: number;
    itemName: string;
    newCounts?: Array<number | undefined>;
    dateCounted?: string;
    cycleCountItemRow: CycleCountItemTableRow;
    systemCount?: number;
    inventoryAdjustment?: number;
    count?: number;
    underLoadPieces?: number;
    category?: string;
    totalCount?: number;
};

type State = {
    report: CycleCountReport | null;
};
export default defineComponent({
    name: 'cycle-count-report-approve',
    components: {
        BRow,
        BFormInput,
        BTable,
    },
    props: {
        report: {
            type: Object as PropType<CycleCountReport>,
            required: true,
        },
        disableAdjustments: Boolean,
    },
    setup(props) {
        const state = reactive<State>({
            report: props.report,
        });
        function mapItemsToTableData(cycleCountItemTable: CycleCountItemTable): Array<TableData> {
            return cycleCountItemTable.cycleCountItemTableRows.map((i) => ({
                itemId: i.item?.id ?? i.plannedPart?.id ?? 0,
                itemName: i.item?.name ?? i.plannedPart?.part.description ?? '',
                newCounts: i.cycleCountItems.map((cci) => (cci.underLoadPieces !== undefined && cci.count !== undefined && cci.plannedPart === undefined ? (cci.count) + (cci.underLoadPieces) : cci.plannedPart !== undefined ? cci.newCount : cci.count)),
                cycleCountItemRow: i,
            }));
        }

        onMounted(async () => {            
            state.report = props.report;
        });        
        const tableData = computed((): Array<TableData> => mapItemsToTableData(new CycleCountItemTable(state.report?.cycleCountItems as Array<CycleCountItem>)));

        const fields: Array<BTableField<TableData>> = [
            { key: 'itemId', label: getTitleCaseTranslation('core.domain.containerNumber'), width: '140px' },
            { key: 'itemName', label: getTitleCaseTranslation('core.domain.containerName') },
            { key: 'dateCounted', label: getTitleCaseTranslation('core.domain.dateCounted') },
            { key: 'category', label: getTitleCaseTranslation('core.domain.category'), width: '10%' },
            {
                key: 'count',
                label: getTitleCaseTranslation('core.domain.loosePiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'underLoadPieces',
                label: getTitleCaseTranslation('core.domain.underLoadPiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'newCounts',
                label: getTitleCaseTranslation('core.domain.totalCount'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'systemCount',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.systemCount'),
            },
            {
                key: 'inventoryAdjustment',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.adjustmentQuantity'),
            },
        ];

        function updateUnderLoadPiecesAndAdjustmentForRelatedItems(value: number, item: CycleCountItem) {
               
            state.report?.cycleCountItems.forEach(((cycleitem) => {
                if(cycleitem.item?.id === item.plannedPart?.item.id) {                    
                    if(item.totalCount !== undefined && item.totalCount < value) {                        
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) + ((value - item.totalCount) * (item.plannedPart?.itemsPerPallet || 0));
                    } else {
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) - ((item.totalCount || 0) - value) * (item.plannedPart?.itemsPerPallet || 0);
                    }
                    updateAdjustmentQuantity(((cycleitem.count || 0) + (cycleitem.underLoadPieces || 0)), cycleitem as CycleCountItem);
                }
                if(item.plannedPart?.isPalletized && item.plannedPart?.palletItem?.id === cycleitem.item?.id) {
                    if(item.totalCount !== undefined && item.totalCount < value) {
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) + (value - item.totalCount);
                    } else {
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) - ((item.totalCount || 0) - value);
                    }                    
                    updateAdjustmentQuantity(((cycleitem.count || 0) + (cycleitem.underLoadPieces || 0)), cycleitem as CycleCountItem);
                }                
                if(item.plannedPart?.includeTopCap && item.plannedPart?.topCapItem?.id === cycleitem.item?.id) {
                    if(item.totalCount !== undefined && item.totalCount < value) {
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) + (value - item.totalCount);
                    } else {
                        cycleitem.underLoadPieces = (cycleitem.underLoadPieces || 0) - ((item.totalCount || 0) - value);
                    }                    
                    updateAdjustmentQuantity(((cycleitem.count || 0) + (cycleitem.underLoadPieces || 0)), cycleitem as CycleCountItem);
                }              
            }));
            item.newCount = value;
            item.totalCount = value;
            item.setInventoryAdjustment(value);
            if(item.systemCount !== undefined) {
                item.setInventoryAdjustment((value) - (item.systemCount));
            }            
        }        
        function updateAdjustmentQuantity(value: number | undefined, item: CycleCountItem) {
            if (value !== undefined) {                
                if (item.plannedPart === undefined) {
                    item.setInventoryAdjustment(value);
                    if (item.systemCount !== undefined) {
                        item.setInventoryAdjustment((value) - item.systemCount);
                    }
                    item.count = value - (item.underLoadPieces || 0);
                    item.newCount = value;
                    item.totalCount = value;
                 } else {  
                    item.count = value;                  
                    updateUnderLoadPiecesAndAdjustmentForRelatedItems(value, item);                    
                }            
            }
        }
        return {
            tableData,
            state,
            fields,
            updateAdjustmentQuantity,
        };
    },
});
